import React, { useState, useReducer } from "react";
import { Card, Col, Row, Divider, Typography, Button } from "antd";
import { ReloadOutlined } from '@ant-design/icons'
import map from "lodash/map";
import data from "./data";
import StatusCard from "./StatusCard";
import Auxiliary from "util/Auxiliary";
const { Text } = Typography

const useForceUpdate = () => {
    const [, setState] = useState()
    return setState
}

const Home = () => {
    const [random, setRandom] = useState(12);
    const forceUpdate = useForceUpdate()

    return (<>
        <Auxiliary>
            <Row gutter={[16, 32]} type="flex" justify="center" align="middle">
                <Col style={{ textAlign: "center" }}>
                    <div className="gx-header-horizontal">
                        <div className="ant-layout-header  gx-header-horizontal-top">
                            <div className="gx-container">
                                <div className="gx-header-horizontal-top-flex">
                                    <div style={{ cursor: 'pointer' }} className="gx-header-horizontal-top-left">
                                        <p style={{ marginTop: -8 }} className="gx-mb-0 gx-text-truncate">
                                            <img style={{ height: 60 }} src={require('./logo512.png')} />
                                            <Text>TouchIT Technologies Firewall Checker</Text>
                                        </p>
                                    </div>


                                    <ul className="gx-login-list">
                                        <li><Button style={{ margin: 0 }} type="primary" icon={<ReloadOutlined />} onClick={() => setRandom(Math.random())} /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>

            <Row gutter={[16, 32]} type="flex">
                {map(
                    data,
                    ({ name, description, url, image, serverIP, serverPort }) => (
                        <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
                            <StatusCard
                                title={name}
                                logo={image}
                                description={description}
                                serverIP={serverIP}
                                serverPort={serverPort}
                                websiteUrl={url}
                                random={random}
                                status={true}
                            />
                        </Col>
                    )
                )}
            </Row>
        </Auxiliary>
    </>);
};

export default Home;
