import React from "react";
import { Card } from "antd";
import PropTypes from "prop-types";

const StatusCardHolder = ({ title, children, styleName, status, cover, extra, actions }) => {

  return (
    <Card style={{ backgroundColor: status ? "green" : "red", height: '100%' }} title={title} actions={actions} cover={cover} className={`gx-card-widget ${styleName}`} extra={extra}>
      {children}
    </Card>
  )
};

export default StatusCardHolder;
StatusCardHolder.defaultProps = {
  styleName: '',
};

StatusCardHolder.propTypes = {
  title: PropTypes.node,
  extra: PropTypes.node,
  cover: PropTypes.node,
  actions: PropTypes.node,
  status: PropTypes.bool,
  children: PropTypes.node.isRequired
};
