import React, { useState, useEffect } from "react";
import { Button } from "antd";
import isReachable from 'is-reachable';
import StatusCardHolder from "./StatusCardHolder";

const StatusCard = ({ title, logo, description, serverIP, serverPort, websiteUrl, random }) => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    
    (async () => {
      try {
        setStatus(await isReachable(websiteUrl))
      } catch (error) {
        setStatus(false)
      }
    })();


    return () => {
      console.log("This will be logged on unmount");
    }
  }, [random])

  return (
    <StatusCardHolder key={random} status={status} styleName="gx-widget-bg">

      <span className="gx-widget-badge">{status ? 'Allowed By Firewall' : 'Blocked by Firewall'}</span>
      <img style={{ width: 'auto', height: 120 }} src={logo} />
      <h1 className="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">{title || <>&nbsp;</>}</h1>
      <p>{description || <>&nbsp;</>}</p>

      <div className="gx-currentplan-col">
        <p className="gx-mb-1">
          IP: {serverIP}
        </p>
        <p className="gx-mb-1">
          Port: {serverPort}
        </p>
      </div>
      <Button href={websiteUrl} block className={`gx-mb-1  ${status ? "gx-btn-success" : "gx-btn-danger"}`}>
        Visit Website
      </Button>
    </StatusCardHolder>
  );
};

export default StatusCard;
